var goToTop = {
    step: 0,
    intervalID: null,
    setStep: function (step) {
        this.step = step;
        return this;
    },
    setListener: function (target, duration) {
        var self = this;
        target.addEventListener('click', function () {
            self.intervalID = setInterval(self.scrollTo.bind(self), duration / window.pageYOffset * self.step);
        });
    },
    scrollTo: function () {
        window.pageYOffset === 0 ? clearInterval(this.intervalID) : scrollBy(0, -this.step);
    }
};
var isScrollEnd = function () {
    return (window.innerHeight + window.pageYOffset) >= document.body.clientHeight;
};
var goToLocalLink = {
    step: 0,
    intervalID: null,
    currentTarget: null,
    targetPos: null,
    setStep: function (step) {
        this.step = step;
        return this;
    },
    setListener: function (target, duration) {
        var self = this;
        target.forEach(function (elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();
                self.currentTarget = e.target;
                if (!self.currentTarget.hasAttribute('href'))
                    return false;
                var anchor = document.querySelector("a[name='" + self.currentTarget.getAttribute('href').substring(1) + "']");
                if (!anchor)
                    return false;
                var rect = anchor.getBoundingClientRect();
                self.targetPos = rect.top + window.pageYOffset;
                self.intervalID = setInterval(self.scrollTo.bind(self), duration / (window.pageYOffset) * self.step);
            });
        });
    },
    scrollTo: function () {
        window.pageYOffset >= this.targetPos || isScrollEnd() ? clearInterval(this.intervalID) : scrollBy(0, +this.step);
    }
};
window.addEventListener('load', function () {
    var step = 40;
    goToTop.setStep(step).setListener(document.querySelector('.go-to-top'), 100);
    goToLocalLink.setStep(step).setListener(document.querySelectorAll('.local-menu-area nav a'), 100);
    if (document.body.clientWidth === 750) {
        document.querySelector('.global-menu-area').addEventListener('click', function (event) {
            if (event.target.getAttribute('class') === 'global-menu-area') {
                var checkbox = document.getElementById('toggle-menu');
                checkbox.checked = false;
                document.getElementById('toggle-menu').dispatchEvent(new Event('click'));
            }
        });
    }
});
